<template>
  <div class="container">
    <div class="box">
      <div class="title">
        <div>个人中心</div>
        <div>更新你的个人信息</div>
      </div>
      <div class="switchBox">
        <div class="tab">
          <div class="tabItem">
            <img v-if="tabAction == 1" src="../../assets/images/rectangle.png" alt />
            <span :class="tabAction == 1 ? 'textAction' : 'text'" @click="clickTab(1)">个人信息</span>
          </div>
          <div class="tabItem">
            <img v-if="tabAction == 2 ? true : false" src="../../assets/images/rectangle.png" alt />
            <span :class="tabAction == 2 ? 'textAction' : 'text'" @click="clickTab(2)">账号信息</span>
          </div>
        </div>
        <div class="infoBox">
          <div class="userInfo">
            <el-form v-if="tabAction == 1" ref="userForm" :model="userForm" label-width="90px">
              <el-form-item label="用户名">
                <el-input v-model="userForm.username"></el-input>
              </el-form-item>
              <el-form-item label="个性签名">
                <el-input type="textarea" v-model="userForm.desc"></el-input>
              </el-form-item>
              <el-form-item label="头像" style="margin-bottom: 14px">
                <el-upload
                  class="avatar-uploader"
                  action=" https://jsonplaceholder.typicode.com/posts/"
                  :headers="headers"
                  :show-file-list="false"
                  ref="upload"
                  accept=".JPG, .PNG, .JPEG, .jpg, .png, .jpeg"
                  :http-request="uploadFile"
                >
                  <img v-if="userForm.imageUrl" :src="userForm.imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="typeBox">允许的文件类型：png、Jjpg、jpeg</div>
              </el-form-item>
              <el-form-item label="性别">
                <el-radio-group v-model="userForm.sex">
                  <el-radio :label="0">男士</el-radio>
                  <el-radio :label="1">女士</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="所属公司">
                <el-select
                  v-model="userForm.company"
                  placeholder="请选择"
                  filterable
                  disabled
                  @change="changeCompany"
                >
                  <el-option
                    v-for="item in companyList"
                    :key="item.id"
                    :label="item.companyName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="公司地址">
                <el-input v-model="userForm.address" readonly></el-input>
              </el-form-item>
              <el-form-item label="公司电话">
                <el-input v-model="userForm.phone" readonly></el-input>
              </el-form-item>-->
              <el-form-item>
                <el-button type="primary" @click="onSubmit">保存更改</el-button>
                <el-button type="info" @click="cancel">取消</el-button>
                <el-button type="danger" @click="uptTenantId">取消加入公司/团队</el-button>
              </el-form-item>
            </el-form>
            <el-form v-else :model="accountForm" label-width="90px">
              <el-form-item label="手机号">
                <el-input v-model="accountForm.phone" readonly placeholder="未绑定，绑定后获得更佳体验"></el-input>
                <span class="changeTxt" @click="dialogPhone = true">更改</span>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="accountForm.mail" readonly placeholder="未绑定，绑定后获得更佳体验"></el-input>
                <span class="changeTxt" @click="dialogMail = true">更改</span>
              </el-form-item>
              <el-form-item label="账户密码">
                <!-- <el-input
                  :type="pwdAction?'password':'text'"
                  v-model="accountForm.password"
                  readonly
                ></el-input>
                <img
                  class="pwdImg"
                  src="../../assets/images/close.png"
                  alt
                  @click="openBtn"
                  v-if="pwdAction"
                />
                <img class="pwdImg" src="../../assets/images/open.png" alt @click="closeBtn" v-else />
                <span class="changeTxt" @click="dialogPwd = true">更改</span>-->
                <el-button @click="dialogPwd = true">更改密码</el-button>
                <el-button @click="dialogForgetPwd = true">忘记密码</el-button>
              </el-form-item>
              <el-form-item label="账号绑定">
                <div class="bindTitle">绑定第三方帐号</div>
                <div class="des">
                  绑定第三方帐号，可以直接登录花瓣，还可以将花瓣内容同步到以下平台，与更多好友分享。
                  <br />如果当前帐号在对应站点处于登录状态，需退出登录后，才能重新绑定。
                </div>
              </el-form-item>
              <el-form-item>
                <div class="bindBox">
                  <img src="../../assets/images/onwx.png" alt v-if="accountForm.wxId" />
                  <img src="../../assets/images/wx.png" alt v-else />
                  <el-button>绑定</el-button>
                  <div class="idBox">ID：{{ accountForm.wxId }}</div>
                  <div class="unbind" v-show="accountForm.wxId">解绑</div>
                </div>
                <div class="bindBox">
                  <img src="../../assets/images/onqq.png" alt v-if="accountForm.qqId" />
                  <img src="../../assets/images/qq.png" alt v-else />
                  <el-button @click="dialogQq = true">绑定</el-button>
                  <div class="idBox">ID：{{ accountForm.qqId }}</div>
                  <div class="unbind" v-show="accountForm.qqId">解绑</div>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="writeOff">注销账号</div>
                <div class="writeOffDes">注销后账号所有数据将被销毁并不可找回，请谨慎操作。</div>
              </el-form-item>
              <el-form-item>
                <el-button class="writeOffBtn">注销</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="更改手机号码" :visible.sync="dialogPhone">
      <el-form
        :model="accountForm"
        ref="changePhoneForm"
        class="formBox"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item label="旧手机号" prop="phone">
          <el-input v-model="accountForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="新手机号" prop="phone">
          <el-input v-model="accountForm.newPhone"></el-input>
        </el-form-item>
        <el-form-item label="旧手机号验证码" prop="phoneCode">
          <el-input v-model="accountForm.phoneCode" placeholder="请输入" maxlength="4"></el-input>
          <div class="codeBox" v-if="timeFlag" @click="sendCode">发送验证码</div>
          <div style="background: #e7e7e7" class="codeBox" v-else>重新发送({{ time }})S</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPhone = false">取 消</el-button>
        <el-button type="primary" @click="submitPhone">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="更改邮箱" :visible.sync="dialogMail">
      <el-form :model="accountForm" class="formBox">
        <el-form-item label="更改邮箱" label-width="88px">
          <el-input v-model="accountForm.mail"></el-input>
        </el-form-item>
        <el-form-item label="获取验证码" label-width="88px">
          <el-input v-model="accountForm.phoneCode" placeholder="请输入"></el-input>
          <div class="codeBox" v-if="timeFlag" @click="sendCode">发送验证码</div>
          <div style="background: #e7e7e7" class="codeBox" v-else>重新发送({{ time }})S</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogMail = false">取 消</el-button>
        <el-button type="primary" @click="dialogMail = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="更改登录密码" :visible.sync="dialogPwd">
      <el-form
        :model="accountForm"
        ref="changePwdFrom"
        class="formBox"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="accountForm.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="accountForm.oldPassword"
            placeholder="请输入旧密码"
            maxlength="8"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="accountForm.password"
            placeholder="请输入6~8位密码，包含数字和字母"
            maxlength="8"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="确认新密码"  prop="newPassword">
          <el-input type="password" v-model="accountForm.newPassword" placeholder="请再次输入密码" maxlength="8"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPwd = false">取 消</el-button>
        <el-button type="primary" @click="changePwd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="忘记密码" :visible.sync="dialogForgetPwd" label-width="auto">
      <el-form :model="accountForm" ref="ForgetPwdFrom" class="formBox" :rules="rules">
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="accountForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="phoneCode">
          <el-input v-model="accountForm.phoneCode" placeholder="请输入"></el-input>
          <div class="codeBox" v-if="timeFlag" @click="sendCode">发送验证码</div>
          <div style="background: #e7e7e7" class="codeBox" v-else>重新发送({{ time }})S</div>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="accountForm.password" placeholder="请输入6~8位密码，包含数字和字母"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogForgetPwd = false">取 消</el-button>
        <el-button type="primary" @click="forgetPwd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="绑定第三方账号" :visible.sync="dialogQq">
      <div class="headline">快捷登录</div>
      <div class="describe">
        使用
        <span>QQ手机版</span> 扫码登陆
      </div>
      <div class="qRCode"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPwd = false">取 消</el-button>
        <el-button type="primary" @click="dialogPwd = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, baseURL, post, put } from "../../api/http";
// import { setRemPc } from "../../modules/SetRem";
import { compressImage } from "../../modules/CompressImage";

export default {
  data() {
    let checkPwd = (rules, value, callback) => {
      console.log(value);
      if (value.trim().length == 0) {
        callback(new Error("请输入确认密码"));
      } else if (value != this.accountForm.password) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      userForm: {
        username: "",
        desc: "",
        imageUrl: "",
        sex: "",
        company: "",
        address: "",
        phone: "",
      },
      accountForm: {
        phone: "",
        newPhone: "",
        mail: "",
        oldPassword: null,
        password: null,
        newPassword: null,
        wxId: "一只AAAAa",
        qqId: "",
        phoneCode: null,
      },
      rules: {
        phone: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: "请正确输入11位手机号码",
            trigger: "blur",
          },
        ],
        phoneCode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            transform: (value) => value,
            trigger: "blur",
          },
          {
            type: "string",
            message: "请输入不包含空格的字符",
            trigger: "blur",
            transform(value) {
              if (value && value.indexOf(" ") === -1) {
                return value;
              } else {
                return false;
              }
            },
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              var passwordreg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,8}$/;
              if (!passwordreg.test(value)) {
                callback(
                  new Error("密码必须由数字、字母、特殊字符组合,请输入6-8位")
                );
              } else {
                callback();
              }
            },
          },
        ],
        newPassword: [
          {
            validator: checkPwd,
            required: true,
            trigger: "blur",
          },
        ],
      },
      tabAction: 1,
      pwdAction: true,
      dialogPhone: false,
      dialogMail: false,
      dialogPwd: false,
      dialogQq: false,
      dialogForgetPwd: false,
      time: 10,
      timeFlag: true,
      baseUrl: baseURL[process.env.NODE_ENV + ""],
      headers: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      userInfo: {},
      companyList: [], // 公司列表
      imagesAddr: "", //图片路径
      infoList:{},
    };
  },
  created() {
    // setRemPc();
    // window.addEventListener("resize", setRemPc); //浏览器窗口大小改变时调用rem换算方法
    this.getInfo();
    get("/api/UserImage?UserId=" + this.userInfo.id).then((res) => {
      if (res.value != null) {
        this.userForm.imageUrl = res.value.imagesAddr?res.value.imagesAddr:'';
      }
    });
  },
  methods: {
    getInfo() {
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
      get("/api/User/GetByUser?UserNo=" + this.userInfo.userNo).then((res) => {
        if(res.code == 200){
          this.userForm.username = res.data[0].userName;
          this.userForm.desc = res.data[0].signature;
          this.userForm.sex = res.data[0].sex;
          this.userForm.company = res.data[0].upCompanyId?res.data[0].upCompanyId:'';
          this.getCompany();
          this.accountForm.phone = res.data[0].phone;
          this.accountForm.mail = res.data[0].email;
          this.infoList = res.data[0];
        }
      });
    },
    // 查询公司
    getCompany() {
      get("/api/Company/GetAll").then((res) => {
        this.companyList = res.data;
        this.companyList.forEach((item) => {
          if (this.userForm.company == item.id) {
            this.userForm.company = item.id;
            this.userForm.address =
              item.companyAddress1 +
              item.companyAddress2 +
              item.companyAddress3 +
              item.companyDetailAddr;
            this.userForm.phone = item.companyPhone;
          }
        });
      });
    },
    // 选择公司切换地址手机号
    changeCompany(e) {
      this.companyList.forEach((item) => {
        if (e == item.id) {
          this.userForm.address =
            item.companyAddress1 +
            item.companyAddress2 +
            item.companyAddress3 +
            item.companyDetailAddr;
          this.userForm.phone = item.companyPhone;
        }
      });
    },
    clickTab(e) {
      this.tabAction = e;
    },
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file);
          } else {
            //result压缩结果
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result);
          }
        });
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadImg", formData)
        .then((res) => {
          this.$refs.upload.clearFiles();
          if (res.code == 200) {
            post("/api/UserImage", {
              userId: this.userInfo.id,
              imagesAddr: this.baseUrl + "/uploads/" + res.value,
            }).then(() => {
              this.$refs.upload.clearFiles();
              get("/api/UserImage?UserId=" + this.userInfo.id).then((res) => {
                if (res.value != null) {
                  this.userForm.imageUrl = res.value.imagesAddr;
                }
              });
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    // handleAvatarSuccess(res, file) {
    //   this.imagesAddr = this.baseUrl + "/uploads/" + res.value;
    //   post("/api/UserImage", {
    //     userId: this.userInfo.id,
    //     imagesAddr: this.imagesAddr,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.$message({
    //         type: "success",
    //         message: "上传成功",
    //       });
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    //   this.userForm.imageUrl = URL.createObjectURL(file.raw);
    // },
    // async beforeAvatarUpload(file) {
    //   const isJPG =
    //     file.type === "image/jpeg" ||
    //     file.type === "image/png" ||
    //     file.type === "image/Jjpg";
    //   const isLt2M = file.size / 1024 / 1024 < 2;

    //   if (!isJPG) {
    //     this.$message.error("上传头像图片只能是 png/Jjpg/jpeg 格式!");
    //   }
    //   if (!isLt2M) {
    //     this.$message.error("上传头像图片大小不能超过 2MB!");
    //   }
    //   return isJPG && isLt2M;
    // },
    // 保存更改
    onSubmit() {
      let form = {
        id: this.userInfo.id,
        userNo: this.userInfo.userNo,
        userName: this.userForm.username,
        upCompanyId: this.userForm.company,
        upDeptmentId: this.userInfo.upDeptmentId,
        post: this.userInfo.post,
        tenantId: this.userInfo.tenantId,
        password: this.userInfo.password,
        phone: this.userInfo.phone,
        email: this.userInfo.email,
        isDelete: this.userInfo.isDelete,
        saveTime: this.userInfo.saveTime,
        saveUserId: this.userInfo.saveUserId,
        signature: this.userForm.desc,
        sex: this.userForm.sex,
      };
      if(form.userName == this.infoList.userName && form.signature == this.infoList.signature && form.sex == this.infoList.sex && form.upCompanyId == this.infoList.upCompanyId){
        this.$message({
          type: "warning",
          message: "未做更改",
        });
        return;
      }
      put("/api/User", form).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          // this.getInfo();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 取消
    cancel() {
      this.getInfo();
    },
    openBtn() {
      this.pwdAction = false;
    },
    closeBtn() {
      this.pwdAction = true;
    },
    // 点击发送手机验证码
    sendCode() {
      get("/api/UserCode/GetPhoneCode?phone=" + this.accountForm.phone).then(
        (res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.timeFlag = false;
            let timetimer = setInterval(() => {
              this.time--;
              if (this.time <= 0) {
                this.timeFlag = true;
                clearInterval(timetimer);
                this.time = 60;
              }
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    // 更改手机号
    submitPhone() {
      this.$refs["changePhoneForm"].validate((valid) => {
        if (valid) {
          get(
            "/api/User/UpdatePhone?phone=" +
              this.accountForm.phone +
              "&NewPhone=" +
              this.accountForm.newPhone +
              "&OldPhoneCode=" +
              this.accountForm.phoneCode
          ).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.dialogPhone = true;
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 更改密码
    changePwd() {
      this.$refs["changePwdFrom"].validate((valid) => {
        if (valid) {
          put(
            "/api/DoLogin/UpdatePwd?Phone=" +
              this.accountForm.phone +
              "&OldPwd=" +
              this.accountForm.oldPassword +
              "&NewPwd=" +
              this.accountForm.password
          ).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.dialogPwd = true;
              window.sessionStorage.removeItem("token");
              this.$router.push("/newLogin");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 忘记密码
    forgetPwd() {
      this.$refs["ForgetPwdFrom"].validate((valid) => {
        if (valid) {
          put(
            "/api/DoLogin/ReturnPwd?Phone=" +
              this.accountForm.phone +
              "&PhoneCode=" +
              this.accountForm.phoneCode +
              "&NewPwd=" +
              this.accountForm.password
          ).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "重置成功",
              });
              this.dialogForgetPwd = true;
              window.sessionStorage.removeItem("token");
              this.$router.push("/newLogin");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //取消加入公司、团队
    uptTenantId() {
      this.$confirm("您确定要退出当前所加入的公司/团队？").then(() => {
        put(
          "/api/User/UptTenantId?UserId=" + this.$store.state.userInfo.id
        ).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "退出成功",
            });
            this.getLogin();
          }
        });
      });
    },
    getLogin() {
      var userInfo = this.$store.state.userInfo;
      get(
        "/api/DoLogin/DoNoPwdMyLogin?UserNo=" +
          userInfo.userNo +
          "&UserName=" +
          userInfo.userName
      ).then((resp) => {
        this.userForm.company = "";
        this.userForm.address = "";
        this.userForm.phone = "";
        this.setData(resp);
      });
    },
    setData(resp) {
      sessionStorage.setItem("userInfo", JSON.stringify(resp.data));
      this.$store.dispatch("userInfo", resp.data);
      sessionStorage.setItem("token", JSON.stringify(resp.data.userToken));
      sessionStorage.setItem("tenantId", JSON.stringify(resp.data.tenantId));
      this.$store.dispatch("tenantId", resp.data.tenantId);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  .box {
    height: 100%;
    background-color: #fafafb;
    border-radius: 0.06rem;
    padding-right: 3.56rem;
    box-sizing: border-box;
    .title {
      padding: 0.21rem 0 0.18rem 0.3rem;
      border-bottom: 0.02rem solid #e9e9e9;
      margin-bottom: 0.24rem;
      font-family: Microsoft YaHei;
      box-sizing: border-box;
      & div:nth-child(1) {
        height: 0.16rem;
        line-height: 0.16rem;
        font-size: 0.16rem;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0.07rem;
      }
      & div:nth-child(2) {
        height: 0.12rem;
        line-height: 0.12rem;
        font-size: 0.12rem;
        font-weight: 400;
        color: #999999;
      }
    }
    .switchBox {
      height: calc(100% - 1.3rem);
      display: flex;
      justify-content: right;
      .tab {
        margin-right: 0.28rem;
        margin-left: 2.33rem;
        .tabItem {
          margin-bottom: 0.25rem;
          img {
            vertical-align: middle;
            width: 4px;
            height: 21px;
          }
          .text {
            margin-left: 0.11rem;
            vertical-align: middle;
            width: 0.64rem;
            height: 0.17rem;
            line-height: 0.17rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .textAction {
            margin-left: 0.11rem;
            vertical-align: middle;
            width: 0.64rem;
            height: 0.17rem;
            line-height: 0.17rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
          }
        }
      }
      .infoBox {
        height: 100%;
        margin-right: 0.06rem;
        flex: 1;
        background: #ffffff;
        padding: 0.28rem 0 0.15rem 0.55rem;
        box-sizing: border-box;
        .userInfo {
          .el-form {
            .el-form-item {
              margin-bottom: 0.22rem !important;
              ::v-deep .el-form-item__label {
                height: 0.43rem !important;
                line-height: 0.43rem;
                font-size: 0.14rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                text-align: left;
              }
              .pwdImg {
                position: absolute;
                left: 3rem;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
              }
              .changeTxt {
                position: absolute;
                left: 3.4rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0.14rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
              }
              .bindTitle {
                height: 0.14rem;
                line-height: 0.14rem;
                font-size: 0.14rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin-bottom: 0.2rem;
              }
              .des {
                height: 0.35rem;
                font-size: 0.14rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
                line-height: 0.2rem;
              }
              .bindBox {
                width: 3.85rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.17rem;
                .el-button {
                  width: 1.02rem;
                  height: 0.43rem !important;
                  background: #f3f6f9;
                  border: 1px solid #f6f6f6;
                  border-radius: 0.13rem !important;
                  margin-left: 0.11rem;
                  margin-right: 0.22rem;
                  font-size: 0.14rem;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                }
                .idBox {
                  flex: 1;
                  font-size: 0.14rem;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #999999;
                }
                .unbind {
                  font-size: 0.14rem;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #4582ff;
                  cursor: pointer;
                }
              }
              .writeOff {
                height: 0.14rem;
                line-height: 0.14rem;
                font-size: 0.14rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin-bottom: 0.2rem;
              }
              .writeOffDes {
                height: 0.15rem;
                font-size: 0.14rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
                line-height: 0.2rem;
              }
              .writeOffBtn {
                width: 1.14rem;
                height: 0.54rem !important;
                background: #f3f6f9;
                border: 1px solid #f6f6f6;
                border-radius: 0.13rem !important;
                font-size: 0.14rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
              }
              ::v-deep .el-input__inner {
                width: 3.85rem;
                height: 0.43rem !important;
                line-height: 0.43rem;
                background: #f3f6f9;
                border: 1px solid #f6f6f6;
                border-radius: 0.06rem;
                font-size: 0.14rem !important;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
              }

              ::v-deep .el-textarea__inner {
                width: 3.85rem;
                height: 1.03rem;
                background: #f3f6f9;
                border: 1px solid #f6f6f6;
                border-radius: 0.06rem;
                font-size: 0.14rem !important;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
              }
              ::v-deep .el-form-item__content {
                line-height: 0.43rem !important;
              }
              ::v-deep .avatar-uploader .el-upload {
                width: 1.11rem !important;
                height: 1.11rem !important;
                border: 1px dashed #d9d9d9;
                border-radius: 0.54rem;
                cursor: pointer;
                position: relative;
                overflow: hidden;
              }
              ::v-deep .avatar-uploader .el-upload:hover {
                border-color: #409eff;
              }
              ::v-deep .avatar-uploader-icon {
                font-size: 0.28rem;
                color: #8c939d;
                width: 1.11rem !important;
                height: 1.11rem !important;
                line-height: 1.11rem;
                text-align: center;
              }
              ::v-deep .el-icon-plus {
                top: 0 !important;
                right: 0 !important;
                background: none;
                padding: 0;
              }
              ::v-deep .avatar {
                width: 1.11rem !important;
                height: 1.11rem !important;
                display: block;
              }
              .typeBox {
                height: 0.13rem;
                line-height: 0.13rem;
                font-size: 0.12rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .el-dialog {
    width: 4.95rem;
    height: 3.66rem;
    border-radius: 0.06rem;
    .el-dialog__header {
      .el-dialog__title {
        font-size: 0.16rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
    .el-dialog__footer {
      position: absolute;
      padding-bottom: 0;
      right: 0;
      bottom: 0.14rem;
    }
    .headline {
      width: 100%;
      text-align: center;
      height: 0.23rem;
      line-height: 0.23rem;
      font-size: 0.24rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
    }
    .describe {
      margin-top: 0.15rem;
      text-align: center;
      font-size: 0.14rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #646464;
      span {
        color: #3686ff;
      }
    }
    .qRCode {
      margin: 0.3rem auto;
      width: 1.04rem;
      height: 1.03rem;
      border: 1px solid skyblue;
    }
  }
  .formBox {
    .el-form-item {
      height: 0.4rem;
      ::v-deep .el-form-item__content {
        height: 100% !important;
        display: flex;
      }
      .el-input {
        margin-right: 0.05rem;
        height: 0.4rem !important;
      }
      ::v-deep .el-input__inner {
        flex: 1;
        height: 0.4rem !important;
        border: 1px solid #d2d7e7 !important;
        border-radius: 0.06rem !important;
        font-size: 0.14rem !important;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
    .codeBox {
      width: 1.2rem;
      height: 0.4rem;
      line-height: 0.4rem;
      text-align: center;
      border: 1px solid #d2d7e7;
      border-radius: 0.06rem;
      font-size: 0.12rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
</style>